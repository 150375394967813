<template>
  <section id="whatWeAre">
    <div class="mobile-subnav">
      <SideBarNavMobile subsectionTitle="What We Are" />
    </div>
    <div class="whatweare-cnt">
      <div class="contentBox">
        <div class="title">
          <img src="@/assets/images/VenueOverview/whatWeAre/impactful-icon.svg" class="img-icon impactful">
          <img src="@/assets/images/VenueOverview/whatWeAre/impactful-text.svg" class="img-title impactful">
        </div>
        <div class="sub-title">im·pact·ful | \ im-ˈpakt-fəl: Producing a forceful impression</div>
        <div class="text">At Allegiant Stadium, no two events are the same and we pride ourselves on the impactful impression we will make on each guest.</div>
      </div>
      <div class="contentBox">
        <div class="title">
          <img src="@/assets/images/VenueOverview/whatWeAre/light-bulb-icon.svg" class="img-icon experiential">
          <img src="@/assets/images/VenueOverview/whatWeAre/experiential-text.svg" class="img-title experiential">
        </div>
        <div class="sub-title">ex·pe·ri·en·tial | \ ik-ˌspir-ē-ˈen(t)-shəl: An immersive & bold experience</div>
        <div class="text">We’re raising the bar with our level of interactive and experiential activities that can be added to each event.</div>
      </div>
      <div class="contentBox">
        <div class="title">
          <img src="@/assets/images/VenueOverview/whatWeAre/limitless-icon.svg" class="img-icon limitless">
          <img src="@/assets/images/VenueOverview/whatWeAre/limitless-text.svg" class="img-title limitless">
        </div>
        <div class="sub-title">lim·it·less | \ ˈli-mət-ləs: without end, limit, or boundary</div>
        <div class="text">The creative possibilities are limitless and we intend to go above and beyond to bring each event to life.</div>
      </div>
    </div>
  </section>
</template>

<script>
import Overview from '@/components/global/Overview'
import { getOverviewData } from '@/data/sectionData/eventSpaces/RaidersLockerRoom'
import SideBarNavMobile from '@/components/Nav/SideBarNavMobile'

export default {
  name: "WhatWeAre",
  components: {
    Overview,
    SideBarNavMobile
  },
  data() {
    return {
      overviewData: getOverviewData()
    }
  }
};
</script>

<style lang="scss" scoped>
  #whatWeAre {
    width: 100vw;
    height: 100vh;
    background: url('~@/assets/images/VenueOverview/whatWeAre/bg-whatWeAre.png') scroll no-repeat center center;
    background-size: cover;
    padding: 160px 0 20px 268px;
    @media (max-width: $lg) {
			padding: 160px 0px 50px 0px;
			height: auto;
      background: url('~@/assets/images/MobileBG/What-We-Are-BG.jpg') fixed no-repeat center center;
      background-size: cover;
		}
    .whatweare-cnt {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      height: 100%;
      overflow-y: auto;
      margin: 0 auto;
      padding: 0 20px;
      justify-content: center;
      align-items: center;
      .contentBox {
        width: 100%;
        max-width: 800px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: 31px auto;
        &:first-child {
          margin: 0 auto 31px;
        }
        &:last-child {
          margin: 31px auto 0;
        }
        .title {
          position: relative;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          @media (max-width: $lg) {
            display: block;
          }
          .img-icon {
            position: relative;
            @media (max-width: $lg) {
              width: 50%;
              margin: auto;
            }
            &.impactful {
              width: 112.5px;
              margin-right: -20px;
              top: 6px;
              @media (max-width: $lg) {
                width: 40%;
                margin: auto;
              }
            }
            &.experiential {
              width: 40px;
              margin-right: 5px;
              @media (max-width: $lg) {
                width: 28%;
                margin: auto;
              }
            }
            &.limitless {
              width: 55px;
              margin-right: 5px;
              @media (max-width: $lg) {
                width: 30%;
                margin: 0 auto 20px auto;
              }
            }
          }
        }
        .sub-title {
          margin: 18px auto 12px;
          font-size: 17px;
          font-family: 'ArtegraSans-Bold';
          line-height: 17px;
          letter-spacing: 1px;
          text-align: center;
          text-transform: uppercase;
          color: #FFFFFF;
        }
        .text {
          max-width: 690px;
          margin: 0 auto;
          font-size: 16px;
          font-family: 'ArtegraSans-Regular';
          line-height: 23px;
          letter-spacing: 1.07px;
          text-align: center;
          color: #FFFFFF;
        }
      }
    }
    .mobile-subnav {
			display: none;
			@media (max-width: $lg) {
				display: block;
			}
		}
  }
</style>